




import { Prop, Watch, Emit } from 'vue-property-decorator';
import { addressService } from '@/services';
import InputWithValidation from './InputWithValidation';
import { AxiosResponse } from 'axios';
import { SubCityStreetAddress } from '@/models';
import Component from 'vue-class-component';

@Component
export default class HouseNumberSelector extends InputWithValidation {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly label!: string;
  @Prop({ type: Number, required: false }) public readonly streetSubCityId!: number;
  @Prop(String) public value!: string | null;
  @Prop(Boolean) public readonly disabled!: boolean | null;
  @Prop(Boolean) public readonly required?: boolean;
  @Prop({ type: Boolean, default: false }) public readonly oresAddressOnly!: boolean;
  //#endregion

  //#region [Data]
  public houseNumbers: SubCityStreetAddress[] = [];
  public selectedHouseNumber: string | null = this.value;
  //#endregion

  //#region [Watch]
  @Watch('streetSubCityId')
  public onStreetSubCityIdChanged(): void {
    this.$nextTick(() => {
      this.fetchHouseNumbers();
    });
  }

  @Watch('value')
  public onValueChanged(newValue: string | null) {
    this.selectedHouseNumber = newValue;
  }

  @Watch('selectedHouseNumber')
  public onSelectedHouseNumberChanged(newValue: string | null) {
    this.onSelectedStreetSubCityChanged(newValue);
  }
  //#endregion

  //#region [Method]
  public fetchHouseNumbers(): void {
    const streetSubCityId = this.streetSubCityId;
    if (streetSubCityId === null || isNaN(streetSubCityId) || streetSubCityId === -1) {
      this.houseNumbers = [];
      if (this.streetSubCityId === -1) {
        this.selectedHouseNumber = this.value;
      } else {
        this.selectedHouseNumber = null;
      }
      return;
    }
    // subcomponents shouldn't call this.clearApiErrors()
    this.$addressRepository
      .get(`addresses?streetSubcityId=${streetSubCityId}`, {
        cache: {
          ignoreCache: false,
        },
      })
      .then(
        (response: AxiosResponse<SubCityStreetAddress[]>) => {
          const apiHouseNumbers = response.data
            .sort((x, y) => addressService.compareHouseNumber(x.houseNumber, y.houseNumber));
          const selectedHouseNumber = this.selectedHouseNumber;
          // update current house numbers after search
          this.houseNumbers = apiHouseNumbers;
          this.selectedHouseNumber = selectedHouseNumber;
        },
        (error: any) => {
          this.houseNumbers = [];
        },
      );
  }

  public updateValue(value: string | null) {
    if (value !== null && value.length === 0) {
      value = null;
    }
    if (value !== '-1') {
      this.$emit('input', value);
    }
  }

  public mounted() {
    if (this.streetSubCityId !== null) {
      this.fetchHouseNumbers();
    }
  }

  public createDefaultNumber(text: string | null): string | null {
    if (!text) {
      return null;
    }

    return text;
  }
  //#endregion

    //#region [Emit]
  @Emit('input')
  public onSelectedStreetSubCityChanged(houseNumber: string | null): string | null {
    return houseNumber;
  }
  //#endregion
}
