












import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import { ApiErrorNotification } from '@/models/api-error-notification'

@Component
export default class ApiErrors extends Vue {
  //#region [Data]
  @State('apiErrors', { namespace: 'apiErrors' })
  public readonly apiErrors!: ApiErrorNotification | null
  //#endregion
}
