import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';

@Component({
  components: {},
})
export default class Step extends Vue {
  //#region [Data]
  @State('isBack')
  public isBack!: boolean
  //#endregion

  //#region [Mutation]
  @Mutation('SET_ISBACK')
  public setIsBack!: (data: boolean) => void
  //#endregion

  //#region [Method]
  public async save(next: boolean, isValid: boolean): Promise<boolean | void> { /* Abstract */ }

  public async change(next: boolean): Promise<-1 | 0 | 1> {
    this.$store.dispatch('apiErrors/clear');
    const isValid = await this.$validatorObs.validate();
    if (next && !isValid) {
      const fieldsInError = this.$validatorObs.$el.getElementsByClassName('lib__field--error');
      if (fieldsInError.length > 0) {
        fieldsInError[0].scrollIntoView();
      }
    }
    const result = await this.save(next, isValid);
    if (result === undefined || result) {
      return isValid ? 1 : 0;
    } else {
      return -1;
    }
  }

  public async next() {
    const result = await this.change(true);
    if (result === 1) {
      this.$emit('next');
    }
  }

  public async previous() {
    const result = await this.change(false);
    if (result !== -1) {
      const isValid = result === 1;
      this.$emit('previous', isValid);
    }
  }

  public setNextButtonStatus(isActive: boolean): void {
    this.$emit('canContinue', isActive)
  }
  //#endregion
}
