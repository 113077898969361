











































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import { chatService } from '@/services'

@Component({ name: 'step-actions' })
export default class StepActions extends Vue {
  //#region [Property]
  @Prop({ type: Boolean, required: false, default: true }) public readonly hasPrevious!: boolean;
  @Prop({ type: Boolean, required: false, default: true }) public readonly hasNext!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly hasPrint!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly hasReset!: boolean;
  @Prop({ type: Boolean, required: false, default: true }) public readonly canContinue!: boolean;
  @Prop({ type: String, required: false, default: null }) public readonly loading!: string;
  @Prop({ type: String, required: false, default: null }) public readonly nextLabel!: string;
  @Prop({ type: String, required: false, default: null }) public readonly previousLabel!: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly showFaq!: boolean
  @Prop({ type: String, required: false, default: null }) public readonly faqLabel!: string
  @Prop({ type: Boolean, required: false, default: false }) public readonly showChatButton!: boolean
  @Prop({ type: String, required: false, default: null }) public readonly faqLink!: string
  @Prop({ type: String, required: false, default: null }) public readonly linkLabel!: string
  //#endregion

  //#region [Mutation]
  @Mutation('SET_ISBACK')
  private setIsBack!: (data: boolean) => void
  //#endregion

  //#region [Computed]
  get nextButtonLabel(): string {
    return this.nextLabel ? this.nextLabel : this.$t('common.nextButton').toString()
  }
  get previousButtonLabel(): string {
    return this.previousLabel ? this.previousLabel : this.$t('common.previousButton').toString()
  }
  //#endregion

  //#region [Method]
  public openChat() {
    chatService.openChat()
  }

  public print(): void {
    window.print();
  }

  public getState(key: string, loading: string, canContinue: boolean = true) {
    if (!canContinue) {
      return 'lib__btn--disabled';
    }

    if (!loading) {
      return null;
    }

    return key === loading ? 'lib__btn--loading' : 'lib__btn--disabled';
  }
  //#endregion

  //#region [Emit]
  @Emit('next')
  public next() {
    return true;
  }

  @Emit('previous')
  public previous() {
    this.setIsBack(true)
    return true;
  }

  @Emit('reset')
  public reset() {
    return true;
  }
  //#endregion
}
