




import { Vue, Prop, Component, Watch, Emit } from 'vue-property-decorator';
import InputWithValidation from './InputWithValidation';
import { SubCityStreet, StreetSubCity } from '@/models';
import { AxiosResponse } from 'axios';

@Component({
  components: {},
})
export default class StreetSubCitySelector extends InputWithValidation {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly label!: string;
  @Prop({ type: Number, required: false }) public readonly subCityId?: number | null;
  @Prop(Boolean) public readonly required!: boolean | null;
  @Prop(Boolean) public readonly disabled!: boolean | null;
  @Prop(Object) public readonly value!: SubCityStreet | null;
  @Prop({ type: Boolean, default: false }) public readonly oresAddressOnly!: boolean;
  //#endregion

  //#region [Data]
  public streets: SubCityStreet[] = [];
  public selectedStreetSubCity: SubCityStreet | null = this.value;
  //#endregion

  //#region [Computed]
  public get selection(): SubCityStreet | null {
    return this.selectedStreetSubCity;
  }
  public set selection(value: SubCityStreet | null) {
    const street = value;
    if (street) {
      this.notifyValidation(true);
      this.onSelectedStreetSubCityChanged(street);
    } else {
      this.notifyValidation(!this.required);
      this.onSelectedStreetSubCityChanged(null);
    }
  }
  //#endregion

  //#region [Watch]
  @Watch('subCityId')
  public onSubCityIdChanged() {
    this.$nextTick(() => {
      this.fetchStreets();
    });
  }

  @Watch('required')
  public onRequiredChanged(required: boolean) {
    if (!required) {
      this.selection = null;
    }
  }

  @Watch('value')
  public onValueChanged(newValue: StreetSubCity | null) {
    this.selectedStreetSubCity = newValue;
  }
  //#endregion

  //#region [Method]
  public mounted() {
    if (this.subCityId !== null) {
      this.fetchStreets();
    }
  }
  public fetchStreets() {
    const subCityId = this.subCityId;
    if (subCityId === null || subCityId === -1) {
      this.streets = [];
      if (this.value?.georesStreetId === -1) {
        this.selection = this.value;
      } else {
        this.selection = null
      }
      return;
    }
    // subcomponents shouldn't call this.clearApiErrors()
    this.$addressRepository
      .get(`streets?subcityId=${subCityId}`, {
        cache: {
          ignoreCache: false,
        },
      })
      .then(
        (response: AxiosResponse<SubCityStreet[]>) => {
          const streets = response.data;

          this.streets.splice(0);
          for (const street of streets) {
            this.streets.push(street);
          }

          if (this.selectedStreetSubCity !== null) {
            const street = this.streets.find((s) =>
              s.georesStreetSubcityId === this.selectedStreetSubCity!.georesStreetSubcityId);
            if (street) {
              this.selection = street;
              return;
            }
          }

          this.selection = this.value;
        },
        (error) => {
          this.notifyValidation(false);
        },
      );
  }

  public createDefaultStreetSubCity(text: string | null): StreetSubCity | null {
    if (!text) {
      return null;
    }

    return {
      georesStreetId: -1,
      georesStreetSubcityId: -1,
      name: text,
    };
  }
  //#endregion

  //#region [Emit]
  @Emit('input')
  public onSelectedStreetSubCityChanged(streetSubCity: StreetSubCity | null): StreetSubCity | null {
    return streetSubCity;
  }
  //#endregion
}
